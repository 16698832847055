import { useApolloClient, useReactiveVar } from '@apollo/client';
import React, { useContext } from 'react';
import { useEffect, useMemo, useState, createContext } from 'react';
import AuthenticationService from '../Helper/AuthenticationService';
import { Logger } from '../Helper/Logger';
import { GetCurrentUser_currentUser } from '../../../operation-result-types';
import { ClearGuestToken, RefreshUserState } from '../Helper/UserHelpers';
import { cachedTokenReactiveVar } from '../GraphQL/Waffle/ApolloClientBuilder';
import { useUserTracking } from '../../lib/user-tracking/UserTrackingProvider';
import { RootStackParamList } from '../../screen-config';
import { createTelemetryEntry } from '../../lib/telemetry';

interface AuthContextType {
  isLoading: boolean;
  isGuest?: boolean;
  token?: string;
  user?: GetCurrentUser_currentUser;
  initialScreen?: keyof RootStackParamList;
  setIsSignedIn: (
    token: string,
    user?: GetCurrentUser_currentUser,
    isGuest?: boolean,
    initialScreen?: keyof RootStackParamList
  ) => void;
  refreshUser: () => void;
}

const AuthContext = createContext<AuthContextType>({
  isLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsSignedIn: (
    token: string,
    user: GetCurrentUser_currentUser,
    isGuest?: boolean
  ) => {
    console.error('implement setIsSignedIn');
  },
  refreshUser: () => console.error('implement refreshUser'),
});

interface SignInState {
  isLoading?: boolean;
  isGuest?: boolean;
  user?: GetCurrentUser_currentUser;
  initialScreen?: keyof RootStackParamList | null;
}

export function AuthProvider({ children }) {
  const apolloClient = useApolloClient();
  const token = useReactiveVar(cachedTokenReactiveVar);
  const userTracking = useUserTracking();
  const [signInState, setSignInState] = useState<SignInState>({
    isLoading: true,
    isGuest: false,
    user: null,
    initialScreen: null,
  });

  useEffect(() => {
    AuthenticationService.Initialize();
  }, []);

  const authContext = useMemo<AuthContextType>(
    () => ({
      setIsSignedIn: (token, user, isGuest, initialScreen) => {
        cachedTokenReactiveVar(token);
        setSignInState({
          isLoading: false,
          isGuest,
          user,
          initialScreen,
        });

        if (user && !isGuest) {
          userTracking?.linkAnonymousUserToCreatedUser(user.id);
        }
        if (!token && !user && !isGuest) {
          ClearGuestToken();
        }
      },
      refreshUser: () => {
        RefreshUserState(apolloClient).then((user) => {
          if (user) {
            setSignInState((existing) => ({ ...existing, user }));
          }
        });
      },
      token,
      isLoading: signInState.isLoading,
      isGuest: signInState.isGuest,
      user: signInState.user,
      initialScreen: signInState.initialScreen,
    }),
    [signInState]
  );

  // Bootstrap
  useEffect(() => {
    (async () => {
      try {
        Logger('AuthLoadingScreen.useEffect: starting');
        const { token, user, isGuest, initialScreen } =
          await AuthenticationService.RestoreUserAndStart(apolloClient);
        Logger('AuthLoadingScreen.useEffect: auth loading promise done');
        setSignInState({
          user,
          isLoading: false,
          isGuest,
          initialScreen: initialScreen as keyof RootStackParamList,
        });
        cachedTokenReactiveVar(token);
        createTelemetryEntry();
      } catch (e) {
        Logger(
          `AuthLoadingScreen.useEffect error: ${JSON.stringify(e.message)}`
        );
      }
    })();
  }, [apolloClient]);

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);
