import { useApolloClient, useQuery } from '@apollo/client';
import React, { useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components/native';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import moment from 'moment';

import WaffleText from '../Components/WaffleText';
import {
  GET_LOCAL_POSITION,
  GET_TRAFFIC,
  GET_WEATHER,
} from '../GraphQL/Waffle/Queries';
import { Horizontal } from '../Components/Horizontal';
import WeatherBox from '../Components/DailyLifeSaver/WeatherBox';
import Section from '../Components/DailyLifeSaver/Section';
import { PlainView, Spacer } from '../Components/SimpleComponents';
import LoadingScreen from './LoadingScreen';
import { Logger } from '../Helper/Logger';
import { LargerHeaderNavHeight, WaffleOrange } from '../Constants/Style';
import {
  ContentCenterScroll,
  ContentUnified,
} from '../Components/ContentShared';
import GeoService from '../Helper/GeoService';
import { hasGeo } from '../Helper/FeatureFlags';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../../screen-config';
import {
  GetTraffic,
  GetWeather,
  GetWeatherVariables,
} from '../../../operation-result-types';

const DayOfWeek = styled(WaffleText)`
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
`;

const DateText = styled(WaffleText)`
  color: #000;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  /* */
`;

const Text = styled(WaffleText)`
  color: #000;
  font-size: 20px;
  letter-spacing: -0.2px;
  line-height: 25px;
`;

const BoldText = styled(WaffleText)`
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 25px;
`;

const GreatDay = styled(WaffleText)`
  height: 36px;
  color: ${WaffleOrange};
  font-size: 25px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.17px;
  line-height: 36px;
  text-align: center;
`;

const Little = styled(WaffleText)`
  color: #fff;
  font-size: 13.6px;
  letter-spacing: -0.14px;
`;

const Today = () => moment().format('MMMM Do, YYYY');
const DayOfWeekText = () => moment().format('dddd');

const DailyLiveSaverTitleView = styled.View`
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  padding-left: 20px;
  padding-bottom: 12px;
  background-color: ${WaffleOrange};
`;

// yesterday, today, tomorrow, now
const DailyLifeSaverScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Daily Life Saver'>) => {
  const client = useApolloClient();
  const {
    data: { position },
  } = useQuery(GET_LOCAL_POSITION);
  const {
    data: { weatherToday: weather } = {},
    loading: weatherLoading,
    error: weatherError,
  } = useQuery<GetWeather, GetWeatherVariables>(GET_WEATHER, {
    variables: {
      latitude: position.latitude,
      longitude: position.longitude,
    },
  });
  const {
    data: { traffic },
  } = useQuery<GetTraffic>(GET_TRAFFIC);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Daily LifeSaver',
      headerTitle: () => (
        <DailyLiveSaverTitleView>
          <Spacer y={1} />
          <DayOfWeek>{DayOfWeekText()}</DayOfWeek>
          <DateText>{Today()}</DateText>
        </DailyLiveSaverTitleView>
      ),
      headerBackTitleVisible: false,
      headerStyle: {
        backgroundColor: WaffleOrange,
        justifyContent: 'center',
        alignItems: 'center',
        height: LargerHeaderNavHeight,
      },
    });
  }, [navigation]);

  useEffect(() => {
    if (hasGeo()) {
      GeoService.startServiceIfNecessary(client).then((_) =>
        Logger('Done starting GeoService')
      );
    }
  }, []);

  if (!weatherLoading) {
    Logger(
      `Location: ${JSON.stringify(position)} Data: ${JSON.stringify(weather)}`
    );

    return (
      <ContentUnified ChildrenWrapper={ContentCenterScroll}>
        <Spacer y={2} />

        <Section
          title={'Weather'}
          imageStyle={{ height: 16, width: 24, marginRight: 6 }}
          imageSource={require('../../assets/elements/dls_weather.png')}>
          <Spacer y={1} />
          <PlainView style={{ height: 55 }}>
            <Horizontal>
              <WeatherBox
                title={'High'}
                value={`${weather.temperatureHigh}\u00B0F`}
              />

              <WeatherBox
                title={'Low'}
                value={`${weather.temperatureLow}\u00B0F`}
              />

              <WeatherBox
                title={'Rain'}
                value={`${weather.precipProbability}%`}
              />

              {weather.uvIndex > 2.0 && (
                <WeatherBox
                  title={'UV'}
                  value={
                    <>
                      {weather.uvIndex}
                      <Little>max</Little>
                    </>
                  }
                />
              )}

              <WeatherBox
                title={'Wind'}
                value={
                  <>
                    {weather.windSpeed}
                    <Little>mph</Little>
                  </>
                }
              />
            </Horizontal>
          </PlainView>
        </Section>

        <Spacer y={3} />

        <Section
          title={'Traffic'}
          imageStyle={{ height: 13, width: 24, marginRight: 6 }}
          imageSource={require('../../assets/elements/dls_traffic.png')}>
          <Text>
            There are {traffic.num_incidents} traffic-related incidents
            (including subway) and closures near you.{' '}
            {traffic.num_incidents > 0 && (
              <BoldText>Nearest: {traffic.nearest}</BoldText>
            )}
          </Text>
        </Section>

        <Spacer y={3} />

        <GreatDay>Have a great day!</GreatDay>
      </ContentUnified>
    );
  }

  if (weatherLoading || weatherError) {
    // @ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
    return <LoadingScreen errors={weatherError} />;
  }

  return <></>;
};

/*
  Temporarily disabled sections:

const comp = (x: any, y: any) => {
  if (x > y) {
    return 'more than';
  }
  if (x < y) {
    return 'less than';
  }
  return 'equal to';
};


          {hasLifeSaverCalendar() && (
          <Section
            title={'Calendar'}
            imageStyle={{ height: 17, width: 18, marginRight: 6 }}
            imageSource={require('../../assets/elements/dls_calendar.png')}>
            {calendar.map((cal: any, idx: any) => (
              <CalEntry key={idx} cal={cal}/>
            ))}
          </Section>
        )}

        {hasLifeSaverHealth() &&
        (steps.yesterday > 0 || stairs.yesterday > 0) && (
          <Section
            title={'Steps'}
            imageStyle={{ height: 23, width: 17, marginRight: 6 }}
            imageSource={require('../../assets/elements/dls_steps.png')}>
            {steps.yesterday > 0 && (
              <Text>
                You walked {commify(steps.yesterday)} steps yesterday.
                This is {comp(steps.yesterday, steps.average)} your
                average of {commify(steps.average)} over the past week.
                {steps.yesterday >= steps.average
                  ? ' Great job!'
                  : ' Taking a 1 mile walk will increase your steps by around 2,000!'}
              </Text>
            )}

            {stairs.yesterday > 0 && (
              <Text>
                You also climbed {commify(stairs.yesterday)} flights of
                stairs yesterday. This is{' '}
                {comp(stairs.yesterday, stairs.average)} your average of{' '}
                {commify(stairs.average)} over the past week.
              </Text>
            )}
          </Section>
        )}


 */
/* Below was immediately before "GreatDay":
          <Section
            title={'Reduce Your Premium'}
            imageStyle={{ height: 18, width: 18, marginRight: 6 }}
            imageSource={require('../../assets/elements/dls_tick.png')}>
            <Text>
              There are a number of actions you could take to reduce your
              premium.
              View{' '}
              <TextLink onPress={async () =>
                await NavigationService.navigate('DeRisking')
              }>
                Reduce Your Premium
              </TextLink>
              {' '}in the Waffle App to see how.
            </Text>
          </Section>
 */

export default DailyLifeSaverScreen;
