import React from 'react';
import { PolicyProps } from '../../../component-config';
import { ProductCyber } from '../../Constants/Products';
import {
  HorizontalSpread,
  VerticalCenter,
} from '../../Components/SimpleLayouts';
import { CardMobile, CardWeb, SectionTitle, WrappedView } from './Shared';
import { Spacer } from '../../Components/SimpleComponents';
import { isFullWeb } from '../../Helper/DeviceHelper';

const provideList = [
  {
    title: 'Money Back',
    description:
      'Money that is stolen from your bank account, tax refund fraud, unemployment fraud, transfers, and others mentioned in your policy.',
  },
  {
    title: 'Expenses for Hired Professionals',
    description:
      'Fees to pay to hire professionals such as lawyers, a digital forensic analyst to help with prosecution, a cyber security consultant, a reputation management firm, and others.',
  },
  {
    title: 'Mental Health Expenses',
    description:
      'Related recovery expenses prescribed for you by a mental health professional who is not your family member or yourself.',
  },
  {
    title: 'Relocation Expenses',
    description:
      'Expenses for temporary relocation within a certain number of days of the cyber event.',
  },
  {
    title: 'Electronic Data Restoration',
    description:
      'Reimbursement of the reasonable cost to restore your damaged data as a result of the cyber event.',
  },
  {
    title: 'Lost Wages',
    description:
      'Reimbursement for wages you lose during a certain number of days after the cyber event.',
  },
  {
    title: 'Device Replacement',
    description:
      'Reimbursement of the reasonable cost to replace or fix your electronic device that was damaged as a result of the cyber event.',
  },
];

const MayProvide = ({ policy }: PolicyProps) => {
  if (policy.productId === ProductCyber) {
    const Card = isFullWeb() ? CardWeb : CardMobile;
    const Wrapper = isFullWeb() ? WrappedView : React.Fragment;
    const width = isFullWeb() ? 200 : 350;

    return (
      <>
        <VerticalCenter>
          <SectionTitle style={{ fontSize: 30 }}>
            Your Cyber Policy may provide the following...
          </SectionTitle>
        </VerticalCenter>

        <Spacer y={3} />

        <Wrapper>
          {provideList.map((provide, idx) => (
            <Card
              key={idx}
              productId={policy.productId}
              title={provide.title}
              description={provide.description}
              width={width}
            />
          ))}
        </Wrapper>
      </>
    );
  }

  return null;
};

export default MayProvide;
