import { useQuery, useMutation } from '@apollo/client';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import WaffleCreateHeader from '../Components/WaffleCreateHeader';
import { Horizontal, VerticalCenter } from '../Components/SimpleLayouts';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import { KeyboardViewMaybe } from '../Components/KeyboardViewMaybe';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../../screen-config';
import { GreenHeaderImage } from '../Components/HeaderNavTitle.web';
import { ContentUnified } from '../Components/ContentShared';
import { Platform } from 'react-native';
import {
  GET_LOCAL_USER,
  UPDATE_USER_DETAILS,
  VALIDATE_USER_ADDRESS,
} from '../GraphQL/Waffle/Queries';
import {
  AddressValidationInput,
  LocalUser,
  UpdateUserDetails,
  UpdateUserDetailsVariables,
  ValidateUserAddress,
  ValidateUserAddress_validateAddress_suggestions,
  ValidateUserAddressVariables,
} from '../../../operation-result-types';
import { PlainView, Spacer } from '../Components/SimpleComponents';
import {
  AutoCompleteAddress,
  validateAddressMessage,
} from '../Components/CreateAccount/AutoCompleteAddress';
import WaffleHalfTextInput from '../Components/WaffleHalfTextInput';
import { CityInput } from '../Components/CreateAccount/CityInput';
import { StateInput } from '../Components/CreateAccount/StateInput';
import { PostalInput } from '../Components/CreateAccount/PostalInput';
import { ErrorText } from '../Components/ErrorText';
import { Logger } from '../Helper/Logger';
import { SuggestedAddressPopup } from '../Components/SuggestedAddressPopup';
import style from '../Constants/Style';
import { Address } from '../Helper/UserHelpers';
import { isNativeOrMobileWeb } from '../Helper/DeviceHelper';

const TITLE = 'Update Address';

const EditAccountScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Edit Account'>) => {
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const [checkAddress] = useMutation<
    ValidateUserAddress,
    ValidateUserAddressVariables
  >(VALIDATE_USER_ADDRESS);
  const [updateUserDetails] = useMutation<
    UpdateUserDetails,
    UpdateUserDetailsVariables
  >(UPDATE_USER_DETAILS);
  const [errorText, setErrorText] = useState<string>();
  const [hideResults, setHideResults] = useState(true);
  const [state, setState] = useState<AddressValidationInput>();
  const [addressSuggestion, setAddressSuggestion] =
    useState<ValidateUserAddress_validateAddress_suggestions>();
  const containerStyle = { width: 300 };

  const doUpdate = async (st) => {
    // Finally, we're good, so update user details
    await updateUserDetails({
      variables: {
        input: {
          ...st,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          __typename: undefined,
        },
      },
    });
    // todo : refetch the user?

    setErrorText('Profile updated');
  };

  const onPick = async (address) => {
    const newState = { ...state, ...address };

    // User performed a correction, so update state, clear errors, and proceed
    await setErrorText(undefined);
    await setState(newState);
    await setAddressSuggestion(undefined);

    await doUpdate(newState);
  };

  const onSubmit = async () => {
    // First, do client-side validation
    const msg = validateAddressMessage(state);

    if (msg) {
      setErrorText(msg);
      Logger(`returning...`);
      return;
    }

    // Then, do server-side validation
    const {
      data: { validateAddress },
    } = await checkAddress({
      variables: {
        input: state,
      },
    });

    if (!validateAddress.isValid) {
      if (validateAddress?.suggestions?.length > 0) {
        setAddressSuggestion(validateAddress.suggestions[0]);
      } else {
        setErrorText(
          validateAddress?.message ?? 'The address does not appear to be valid'
        );
      }
      return;
    }

    await doUpdate(state);
  };

  const onChange = (k: keyof AddressValidationInput, v: string) =>
    setState((st) => ({ ...st, [k]: v }));

  const setAddress = (address: Address) => {
    setState({
      address: `${address.houseNumber} ${address.street}`,
      city: address.city,
      state: address.state,
      zip: address.postalCode,
    });
  };

  const validateAddressLocal = () =>
    setErrorText(validateAddressMessage(state));

  const onBlur = () => {
    setHideResults(false);
    validateAddressLocal();
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: TITLE,
      headerBackTitleVisible: false,
      headerShown: isNativeOrMobileWeb(),
    });
  }, [navigation]);

  useEffect(() => {
    setState({
      address: user.address,
      address2: user.address2,
      city: user.city,
      state: user.state,
      zip: user.zip,
    });
  }, [user]);

  if (!state) {
    return null;
  }

  return (
    <ContentUnified title={TITLE} image={GreenHeaderImage}>
      <KeyboardViewMaybe>
        {Platform.OS !== 'web' && <WaffleCreateHeader title={TITLE} />}

        <VerticalCenter style={[style.fullWidth, style.fullHeight]}>
          {errorText && <ErrorText>{errorText}</ErrorText>}

          <Spacer y={2.5} />

          <PlainView style={containerStyle}>
            <AutoCompleteAddress
              hideResults={hideResults}
              onChoose={(address: any) => setAddress(address)}
              onFocus={() => setHideResults(false)}
              onBlur={() => setTimeout(() => setHideResults(true), 500)}
              value={state.address}
              onChangeText={(rawAddress) => onChange('address', rawAddress)}
            />

            <Spacer y={1} />

            <Horizontal>
              <WaffleHalfTextInput
                placeholder={'Suite, apartment, etc (opt)'}
                value={state.address2}
                otherProps={{
                  textContentType: 'streetAddressLine2',
                  editable: true,
                }}
                // this isn't a standard address change, as it doesn't taint
                // the address.
                onChangeText={(v) => onChange('address2', v)}
                onBlur={onBlur}
              />
            </Horizontal>

            <Spacer y={1} />

            <Horizontal>
              <CityInput
                value={state.city}
                onChangeText={(v) => onChange('city', v)}
                otherProps={{ editable: true }}
                onBlur={onBlur}
              />

              <Spacer x={1} />

              <StateInput
                value={state.state}
                onChangeText={(v) => onChange('state', v)}
                otherProps={{ editable: true }}
                onBlur={onBlur}
              />
            </Horizontal>

            <Spacer y={1} />

            <Horizontal>
              <PostalInput
                value={state.zip}
                onChangeText={(v) => onChange('zip', v)}
                otherProps={{ editable: true }}
                onBlur={onBlur}
              />
            </Horizontal>
          </PlainView>

          <Spacer y={2.5} />

          <WaffleButtonAsync onPress={onSubmit} name={'Update'} />
        </VerticalCenter>

        <SuggestedAddressPopup
          address={state}
          suggestion={addressSuggestion}
          onDismiss={() => setAddressSuggestion(null)}
          onPick={onPick}
        />
      </KeyboardViewMaybe>
    </ContentUnified>
  );
};

export default EditAccountScreen;
