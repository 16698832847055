import React, { useLayoutEffect, useState } from 'react';
import * as yup from 'yup';
import { WaffleButton } from '../Components/WaffleButton';
import WaffleCreateHeader from '../Components/WaffleCreateHeader';
import PasswordInput from '../Components/PasswordInput';
import { HorizontalSpread, VerticalCenter } from '../Components/SimpleLayouts';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import AuthenticationService from '../Helper/AuthenticationService';
import { KeyboardViewMaybe } from '../Components/KeyboardViewMaybe';
import style from '../Constants/Style';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList, SettingsScreenName } from '../../screen-config';
import { GreenHeaderImage } from '../Components/HeaderNavTitle.web';
import { ContentUnified } from '../Components/ContentShared';
import { Platform } from 'react-native';
import { isNativeOrMobileWeb } from '../Helper/DeviceHelper';

const schema = yup.object().shape({
  existingPassword: yup.string().required(),
  password: yup.string().min(8).required(),
  confirmPassword: yup.string().min(8).required(),
});

interface State {
  existingPassword?: string;
  password?: string;
  confirmPassword?: string;
}

const isValid = (state: State) =>
  schema.isValidSync(state) &&
  state.password === state.confirmPassword &&
  state.password != state.existingPassword; // We should not let user change password to existing password

const ChangePasswordScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Change Password'>) => {
  const [state, setState] = useState<State>({});
  const valid = isValid(state);

  const onChangeText = (key: keyof State, text: string) =>
    setState({ ...state, [key]: text });

  const doChange = async () => {
    await AuthenticationService.ChangePassword(
      state.existingPassword,
      state.password
    );
    navigation.navigate(SettingsScreenName);
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
    });
  }, [navigation]);

  return (
    <ContentUnified title={'Change Password'} image={GreenHeaderImage}>
      <KeyboardViewMaybe>
        {isNativeOrMobileWeb() && (
          <WaffleCreateHeader title={'Change Password'} />
        )}

        <VerticalCenter style={[style.fullWidth, style.fullHeight]}>
          <PasswordInput
            label={'Existing Password'}
            onChangeText={(text: string) =>
              onChangeText('existingPassword', text)
            }
            otherProps={{
              autoCompleteType: 'password',
              textContentType: 'password',
            }}
            containerStyle={{
              marginBottom: 20,
              width: 300,
            }}
          />

          <PasswordInput
            label={'New Password'}
            onChangeText={(text: string) => onChangeText('password', text)}
            otherProps={{
              autoCompleteType: 'new-password',
              textContentType: 'newPassword',
            }}
            containerStyle={{
              marginBottom: 20,
              width: 300,
            }}
            footerText={'8 or more characters'}
          />

          <PasswordInput
            label={'Confirm New Password'}
            onChangeText={(text: string) =>
              onChangeText('confirmPassword', text)
            }
            otherProps={{
              autoCompleteType: 'new-password',
              textContentType: 'newPassword',
            }}
            containerStyle={{
              marginBottom: 20,
              width: 300,
            }}
          />

          <HorizontalSpread style={{ width: 300, marginBottom: 10 }}>
            <WaffleButton
              disabled={!valid}
              onPress={async () => await doChange()}
              style={{ width: '48%' }}
              name={'Change'}
            />

            <WaffleButtonAsync
              onPress={async () => navigation.navigate(SettingsScreenName)}
              style={{ width: '48%' }}
              name={'Cancel'}
            />
          </HorizontalSpread>
        </VerticalCenter>
      </KeyboardViewMaybe>
    </ContentUnified>
  );
};

export default ChangePasswordScreen;
