import { useApolloClient } from '@apollo/client';
import {
  HorizontalSpread,
  VerticalCenter,
} from '../../Components/SimpleLayouts';
import { BigBoldText, WaffleTextFamily } from '../../Components/WaffleText';
import { PlainView, Spacer } from '../../Components/SimpleComponents';
import {
  PoweredByBlurb,
  updateCyberPolicyFrequency,
} from '../../Components/DesignPolicy/SuggestedCoverageShared';
import React, { useContext } from 'react';
import { ButtonGroup } from 'react-native-elements';
import { WaffleMediumGrey, WaffleOrange, White } from '../../Constants/Style';
import { Image } from 'react-native';
import { SuggestedCoverageContext } from '../SuggestedCoverageScreen';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import { HapticSelectionOptions } from '../../../component-config';

const SmallCyberChooser = (props) => (
  <ButtonGroup
    buttonStyle={{
      backgroundColor: WaffleMediumGrey,
      padding: 5,
      borderRadius: 0,
    }}
    textStyle={{
      fontFamily: WaffleTextFamily,
      color: White,
      textAlign: 'center',
      fontWeight: '400',
      fontSize: 14,
    }}
    innerBorderStyle={{
      width: 0,
    }}
    selectedButtonStyle={{
      backgroundColor: WaffleOrange,
      borderRadius: 100,
      borderWidth: 1,
      borderColor: WaffleOrange,
    }}
    selectedTextStyle={{
      fontFamily: WaffleTextFamily,
      color: White,
      textAlign: 'center',
    }}
    containerStyle={{
      height: 41,
      width: 178,
      borderRadius: 45,
      backgroundColor: WaffleMediumGrey,
      borderWidth: 1,
      borderColor: WaffleMediumGrey,
      marginHorizontal: 0,
      shadowRadius: 45,
      // borderRadius: 32,
      shadowOffset: { width: 0, height: 4 },
      shadowColor: 'rgba(108,62,12,0.5)',
      shadowOpacity: 1,
      elevation: 5,
    }}
    underlayColor={'#BBBBBB'}
    {...props}
  />
);

const cyberFrequencies = [
  {
    label: 'Monthly',
    value: 'Monthly',
  },
  {
    label: 'Annually',
    value: 'Annual',
  },
];

export const CyberQuoteSummary = () => {
  const client = useApolloClient();
  const { state, setState } = useContext(SuggestedCoverageContext);
  const policy = state.currentPolicy;
  const coverage = policy?.coverage?.find(
    (c) => c.productCoverageId === 'Payment Frequency'
  );

  if (!policy) {
    return null;
  }

  return (
    <>
      <VerticalCenter style={{ width: '100%' }}>
        <BigBoldText
          testID="cyberInsuranceTitle"
          style={{
            color: '#919191', // 'rgba(229,229,229,0.9)',
            fontSize: 44,
            fontWeight: '400',
            lineHeight: 43,
            maxWidth: 334,
            textAlign: 'center',
          }}>
          Personal Cyber Insurance
        </BigBoldText>
      </VerticalCenter>

      <Spacer y={5} />

      <VerticalCenter>
        <Image
          source={require('../../../assets/images/products/cyber/powered_by.png')}
          style={{
            width: 167,
            height: 17,
          }}
          resizeMode={'contain'}
        />

        <Spacer y={2.5} />

        <PlainView>
          <PlainView style={{ opacity: state.updating ? 0.5 : 1.0 }}>
            <SmallCyberChooser
              buttons={['Monthly', 'Annually']}
              selectedIndex={cyberFrequencies.findIndex(
                (f) => f.value === coverage.coverageLimit
              )}
              onPress={async (i) => {
                ReactNativeHapticFeedback.trigger(
                  'selection',
                  HapticSelectionOptions
                );
                await updateCyberPolicyFrequency(
                  client,
                  policy.id,
                  coverage.id,
                  cyberFrequencies[i].value,
                  setState
                );
              }}
            />
          </PlainView>
          <Image
            source={require('../../../assets/images/products/cyber/save5pct.png')}
            style={{
              width: 97,
              height: 55,
              position: 'relative',
              top: -10,
              left: 110,
            }}
          />
        </PlainView>
      </VerticalCenter>

      <Spacer y={3} />
    </>
  );
};
