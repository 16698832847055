import { useApolloClient } from '@apollo/client';
import React, { useContext } from 'react';
import WaffleText from '../../Components/WaffleText';
import { Image, Spacer } from '../../Components/SimpleComponents';
import { GreySection, WhiteSection } from './Shared';
import { Divider } from 'react-native-elements';
import {
  HorizontalPacked,
  HorizontalSpread,
  VerticalLeft,
} from '../../Components/SimpleLayouts';
import { Counter } from '../../Components/DesignPolicy/Counter';
import { SuggestedCoverageContext } from '../SuggestedCoverageScreen';
import { updateCoverageValue } from '../../Components/DesignPolicy/SuggestedCoverageShared';
import { Logger } from '../../Helper/Logger';
import { WaffleLightGrey, WaffleMediumGrey } from '../../Constants/Style';
import { isFullWeb } from '../../Helper/DeviceHelper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const options = {
  Plan1: {
    label: '$10,000',
    next: 'Plan2',
  },
  Plan2: {
    label: '$50,000',
    next: 'Plan3',
    prev: 'Plan1',
  },
  Plan3: {
    label: '$100,000',
    prev: 'Plan2',
  },
};

export const CoverageEditor = () => {
  const client = useApolloClient();
  const { state, setState } = useContext(SuggestedCoverageContext);
  const policy = state.currentPolicy;
  const coverage = policy?.coverage?.find(
    (c) => c.productCoverageId === 'Protection Level'
  );

  if (!policy || !coverage) {
    Logger(`CoverageEditor: null data!`);
    return null;
  }

  const currentOption = options[coverage.coverageLimit];
  const updateLevel = async (newValue) =>
    newValue &&
    (await updateCoverageValue(client, coverage.id, newValue, setState));

  return (
    <GreySection>
      <Spacer y={2} />

      <WaffleText
        style={{
          maxWidth: 334,
          textAlign: 'center',
          fontSize: 28,
          fontWeight: '700',
        }}>
        How much protection do you need?
      </WaffleText>

      <Spacer y={2} />

      <WaffleText
        style={{
          fontSize: 13,
          fontWeight: '400',
          maxWidth: 275,
          textAlign: 'center',
        }}>
        This is the total aggregate amount available under the policy.
      </WaffleText>

      <Spacer y={2} />

      <Counter
        label={currentOption.label}
        updating={state.updating}
        color={WaffleMediumGrey}
        updatingColor={WaffleLightGrey}
        invert={true}
        onPrev={async () =>
          !state.updating && (await updateLevel(currentOption.prev))
        }
        onNext={async () =>
          !state.updating && (await updateLevel(currentOption.next))
        }
        containerStyle={{
          shadowRadius: 100,
          borderRadius: 100,
          shadowOffset: { width: 0, height: 4 },
          shadowColor: 'rgba(108,62,12,0.5)',
          shadowOpacity: 1,
          elevation: 5,
        }}
      />

      <Spacer y={2} />

      <WaffleText
        style={{
          fontSize: 13,
          fontWeight: '400',
          maxWidth: 275,
          textAlign: 'center',
        }}>
        Toggle the plus & minus signs to switch your coverage amount.
      </WaffleText>

      <Spacer y={2} />

      <Divider style={{ width: 330 }} />

      <Spacer y={2} />

      <WaffleText
        style={{
          maxWidth: 334,
          textAlign: 'center',
          fontSize: 28,
          fontWeight: '700',
        }}>
        Who&apos;s Covered?
      </WaffleText>

      <Spacer y={2} />

      <HorizontalSpread
        style={{
          width: isFullWeb() ? 450 : '100%',
          paddingLeft: 10,
          paddingRight: 10,
          alignItems: 'flex-start',
        }}>
        <Image
          source={require('../../../assets/images/products/cyber/covered-shield.png')}
          style={{ width: 108, height: 132 }}
          resizeMode={'contain'}
        />

        <VerticalLeft style={{ width: isFullWeb() ? 300 : 230 }}>
          <Spacer y={1} />

          <HorizontalPacked style={{ alignItems: 'flex-start' }}>
            <Icon name={'check-bold'} size={18} />
            <Spacer x={1.5} />
            <WaffleText style={{ fontSize: 18, fontWeight: '400' }}>
              You
            </WaffleText>
          </HorizontalPacked>

          <Spacer y={2} />

          <HorizontalPacked style={{ alignItems: 'flex-start' }}>
            <Icon name={'check-bold'} size={18} />

            <Spacer x={1.5} />

            <WaffleText style={{ fontSize: 18, fontWeight: '400' }}>
              Family Members who live under your roof (includes spouse and
              parents)
            </WaffleText>
          </HorizontalPacked>

          <Spacer y={2} />

          <HorizontalPacked style={{ alignItems: 'flex-start' }}>
            <Icon name={'check-bold'} size={18} />

            <Spacer x={1.5} />

            <WaffleText style={{ fontSize: 18, fontWeight: '400' }}>
              Your dependents up to 24 years old under your roof
            </WaffleText>
          </HorizontalPacked>
        </VerticalLeft>
      </HorizontalSpread>

      <Spacer y={2} />
    </GreySection>
  );
};
