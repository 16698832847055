import React, { useLayoutEffect, useState } from 'react';
import * as yup from 'yup';
import WaffleCreateHeader from '../Components/WaffleCreateHeader';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import AuthenticationService from '../Helper/AuthenticationService';
import UserNameInput from '../Components/UserNameInput';
import { CenterLinkText, Spacer } from '../Components/SimpleComponents';
import { KeyboardViewMaybe } from '../Components/KeyboardViewMaybe';
import { VerticalCenter } from '../Components/SimpleLayouts';
import style from '../Constants/Style';
import { StackScreenProps } from '@react-navigation/stack';
import {
  ResetPasswordScreenName,
  RootStackParamList,
  StartQuoteScreenName,
} from '../../screen-config';

const schema = yup.string().required().email();

const isValid = (email: string) => schema.isValidSync(email);

const ForgotPasswordScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Forgot Password'>) => {
  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<string>();

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
    });
  }, [navigation]);

  const forgotPassword = async () => {
    try {
      await AuthenticationService.ForgotPassword(email);
      navigation.navigate(ResetPasswordScreenName, { email });
    } catch (e) {
      setError(e);
    }
  };

  return (
    <KeyboardViewMaybe>
      <WaffleCreateHeader
        title={'Forgot your password?'}
        subtitle={
          'Enter your Email below and we will send a message to reset your password'
        }
      />

      <VerticalCenter style={[style.fullWidth, style.fullHeight]}>
        <UserNameInput
          label={'Email'}
          onChangeText={(text: string) => setEmail(text)}
          containerStyle={{ width: 300 }}
        />

        <Spacer y={2} />

        <WaffleButtonAsync
          disabled={!isValid(email)}
          onPress={forgotPassword}
          name={'Reset My Password'}
        />

        <Spacer y={2} />

        <CenterLinkText
          onPress={() => navigation.navigate(StartQuoteScreenName)}>
          Go back
        </CenterLinkText>
      </VerticalCenter>
    </KeyboardViewMaybe>
  );
};

export default ForgotPasswordScreen;
