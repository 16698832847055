import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { VerticalCenter } from '../../Components/SimpleLayouts';
import { WaffleLightGrey, White } from '../../Constants/Style';
import { Spacer } from '../../Components/SimpleComponents';
import WaffleText from '../../Components/WaffleText';
import { GreySection, WhiteSection } from './Shared';
import { SuggestedCoverageContext } from '../SuggestedCoverageScreen';
import { LocalUser } from '../../../../operation-result-types';
import { GET_LOCAL_USER } from '../../GraphQL/Waffle/Queries';
import {
  GetFullPolicy,
  ItemLabel,
  ItemLabelOrangeLink,
} from '../../Components/DesignPolicy/SuggestedCoverageShared';
import { TouchableWithoutFeedback } from 'react-native';
import { ShowWebLink } from '../../Components/WebViewPopupLink';
import WhenTheseHappen from './WhenTheseHappen';
import MayProvide from './MayProvide';

const ForMoreCoverage = ({ fullPolicy }: { fullPolicy: string }) => {
  if (fullPolicy) {
    return (
      <VerticalCenter
        style={{
          backgroundColor: WaffleLightGrey,
          width: 300,
          padding: 16,
          borderRadius: 22,
        }}>
        <TouchableWithoutFeedback
          onPress={() => ShowWebLink(undefined, fullPolicy)}>
          <ItemLabel
            style={{ fontSize: 13, fontWeight: '400', textAlign: 'center' }}>
            For even more coverage, see the{' '}
            <ItemLabelOrangeLink style={{ fontSize: 11, fontWeight: '400' }}>
              full policy
            </ItemLabelOrangeLink>
          </ItemLabel>
        </TouchableWithoutFeedback>
      </VerticalCenter>
    );
  }
  return null;
};

const ImportantNote = ({ fullPolicy }: { fullPolicy: string }) => {
  if (fullPolicy) {
    return (
      <VerticalCenter
        style={{
          backgroundColor: WaffleLightGrey,
          width: 326,
          borderRadius: 23,
          padding: 16,
        }}>
        <WaffleText style={{ fontSize: 20, fontWeight: 'bold' }}>
          Important Note:
        </WaffleText>

        <Spacer y={2} />

        <TouchableWithoutFeedback
          onPress={() => ShowWebLink(undefined, fullPolicy)}>
          <WaffleText style={{ textAlign: 'center', fontSize: 14, width: 250 }}>
            All of the above is subject to specific conditions and limits. We
            really encourage you to read the{' '}
            <ItemLabelOrangeLink style={{ fontSize: 11, fontWeight: '400' }}>
              full policy
            </ItemLabelOrangeLink>{' '}
            to understand all coverage.
          </WaffleText>
        </TouchableWithoutFeedback>
      </VerticalCenter>
    );
  }

  return null;
};

export const PolicyExamples = () => {
  const { state } = useContext(SuggestedCoverageContext);
  const policy = state.currentPolicy;
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const fullPolicy = GetFullPolicy(user, policy?.productId);

  return (
    <WhiteSection>
      <MayProvide policy={policy} />

      <Spacer y={2.5} />

      <ImportantNote fullPolicy={fullPolicy} />

      <Spacer y={2.5} />

      <WhenTheseHappen policy={policy} />

      <Spacer y={2.5} />

      <ForMoreCoverage fullPolicy={fullPolicy} />

      <Spacer y={2.5} />
    </WhiteSection>
  );
};
