import { useQuery } from '@apollo/client';
import React, { useLayoutEffect, useState } from 'react';
import { Divider } from 'react-native-elements';
import Accordion from 'react-native-collapsible/Accordion';

import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import { PlainView, ScrollView, Spacer } from '../Components/SimpleComponents';
import { Markup } from '../Components/Markup';
import { GET_PRODUCTS } from '../GraphQL/Waffle/Queries';
import WaffleText, { LargeText } from '../Components/WaffleText';
import { HorizontalSpread, VerticalSpread } from '../Components/SimpleLayouts';
import styles from '../Constants/Style';
import { GreenHeaderImage } from '../Components/HeaderNavTitle.web';
import { LoadingUnified } from '../Components/LoadingUnified';
import {
  BottomSpacer,
  ContentUnified,
  OnlyBlock,
} from '../Components/ContentShared';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList, Step1ScreenName } from '../../screen-config';
import { isNativeOrMobileWeb } from '../Helper/DeviceHelper';
import {
  GetProducts,
  GetProductsVariables,
} from '../../../operation-result-types';
import { BuildVersionAndPlatform } from '../Helper/UserHelpers';

const title = 'Legal Stuff';
const headerImage = GreenHeaderImage;

type ContinueButtonProps = {
  onPress: () => Promise<void>;
  disabled?: boolean;
};

const ContinueButton = ({ onPress, disabled }: ContinueButtonProps) => (
  <PlainView style={{ alignItems: 'center', width: '100%' }}>
    <WaffleButtonAsync name={'I Agree'} onPress={onPress} disabled={disabled} />
  </PlainView>
);

const DisclosureText = ({ disclosures }) => (
  <Markup
    style={{
      heading1: { lineHeight: 36 },
      heading2: { lineHeight: 28 },
      heading3: { lineHeight: 22 },
    }}>
    {disclosures}
  </Markup>
);

const DisclosuresScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Disclosures'>) => {
  const { loading, error, data } = useQuery<GetProducts, GetProductsVariables>(
    GET_PRODUCTS,
    {
      // fetchPolicy: 'cache-and-network',
      variables: BuildVersionAndPlatform(),
    }
  );
  const [activeSections, setActiveSections] = useState([]);
  const [viewedDisclosure, setViewedDisclosure] = useState([]);

  useLayoutEffect(() => {
    navigation.setOptions({
      title,
      headerLeft: () => <></>,
      headerShown: isNativeOrMobileWeb(),
      gestureEnabled: false,
    });
  }, [navigation]);

  if (loading || error) {
    return (
      <LoadingUnified
        title={title}
        errors={error?.message}
        image={headerImage}
      />
    );
  }

  const products =
    data?.products
      // ?.filter((p: any) => selectedProducts.some(product => p.id === product))
      ?.filter((p: any) => !!p.disclosures)
      ?.sort((a, b) => a.id.localeCompare(b.id))
      ?.map((x) => ({ id: x.id, disclosures: x.disclosures })) ?? [];
  const titles = products.map((p) => `${p.id} disclosures`);

  const renderHeader = (content, index, isActive) => (
    <PlainView style={{ height: 30 }}>
      {index > 0 && <Divider />}
      <Spacer y={1} />
      <HorizontalSpread>
        <LargeText>{content}</LargeText>

        <LargeText
          style={[
            viewedDisclosure.includes(index)
              ? styles.waffleOrange
              : styles.waffleBlack,
          ]}>
          {isActive ? '-' : '+'}
        </LargeText>
      </HorizontalSpread>
      <Spacer y={1} />
    </PlainView>
  );

  const renderContent = (content, index) => (
    <DisclosureText disclosures={products[index].disclosures} />
  );

  const onChange = (sections) => {
    // Logger(`sections: ${JSON.stringify(sections)} viewedDisclosures: ${JSON.stringify(viewedDisclosure)}`)
    setActiveSections(sections);

    if (sections.length > 0 && !viewedDisclosure.includes(sections[0])) {
      // todo : make this more robust!
      setViewedDisclosure([...viewedDisclosure, ...sections]);
    }
  };

  return (
    <ContentUnified
      title={title}
      addingProduct={true}
      ChildrenWrapper={OnlyBlock}
      image={headerImage}>
      <VerticalSpread style={{ marginHorizontal: 32, height: '100%' }}>
        <ScrollView>
          <Spacer y={3} />

          <WaffleText style={{ fontSize: 16 }}>
            Before we start, some legal stuff. Please review our partners’
            disclaimers.
          </WaffleText>

          <Spacer y={2} />

          <Accordion
            sections={titles}
            renderHeader={renderHeader}
            renderContent={renderContent}
            onChange={onChange}
            activeSections={activeSections}
          />
        </ScrollView>

        <Spacer y={2} />

        <ContinueButton
          disabled={viewedDisclosure.length != titles.length}
          onPress={async () => navigation.navigate(Step1ScreenName)}
        />

        <BottomSpacer />
      </VerticalSpread>
    </ContentUnified>
  );
};

export default DisclosuresScreen;
