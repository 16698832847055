import React from 'react';
import {
  HorizontalPacked,
  VerticalCenter,
} from '../../Components/SimpleLayouts';
import { SectionTitle, WhiteSection } from './Shared';
import { OrangeTextLink, Spacer } from '../../Components/SimpleComponents';
import WaffleText from '../../Components/WaffleText';
import { Image, TouchableWithoutFeedback } from 'react-native';
import { Divider } from 'react-native-elements';
import { ShowWebLink } from '../../Components/WebViewPopupLink';

export const PayOrQuestions = () => (
  <WhiteSection>
    <Spacer y={2} />

    <VerticalCenter>
      <SectionTitle style={{ maxWidth: 190 }}>Time to Get Waffle</SectionTitle>
    </VerticalCenter>

    <Spacer y={2.5} />

    <VerticalCenter>
      <Image
        source={require('../../../assets/images/products/cyber/questions.png')}
        style={{ width: 188, height: 186 }}
        resizeMode={'contain'}
      />
      <SectionTitle>Questions?</SectionTitle>

      <WaffleText style={{ maxWidth: 250 }}>
        Learn more about insurance through Waffle by contacting us. We’re always
        here to help!
      </WaffleText>

      <Spacer y={2} />

      <HorizontalPacked>
        <TouchableWithoutFeedback
          onPress={() =>
            ShowWebLink(undefined, 'mailto:contact@waffle-labs.com')
          }>
          <OrangeTextLink>Contact Us</OrangeTextLink>
        </TouchableWithoutFeedback>

        <Spacer x={2} />

        <Divider orientation={'vertical'} width={1} />

        <Spacer x={2} />

        <TouchableWithoutFeedback
          onPress={() =>
            ShowWebLink(
              undefined,
              'https://www.trywaffle.com/help-center/cyber-insurance-faq'
            )
          }>
          <OrangeTextLink>Visit Our FAQ</OrangeTextLink>
        </TouchableWithoutFeedback>
      </HorizontalPacked>
    </VerticalCenter>
  </WhiteSection>
);
