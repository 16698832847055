import { useMutation } from '@apollo/client';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../../../screen-config';
import {
  CreateRentersAdditionalInterest,
  CreateRentersAdditionalInterestVariables,
} from '../../../../operation-result-types';
import { CREATE_RENTERS_ADDITIONAL_INTEREST } from '../../GraphQL/Waffle/Queries';
import React, { useLayoutEffect, useState } from 'react';
import { VerticalCenter } from '../../Components/SimpleLayouts';
import { Spacer } from '../../Components/SimpleComponents';
import {
  ContentCenterScroll,
  ContentUnified,
} from '../../Components/ContentShared';
import { LargeText, MediumText } from '../../Components/WaffleText';
import { isFullWeb, isNativeOrMobileWeb } from '../../Helper/DeviceHelper';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import { ErrorText } from '../../Components/ErrorText';
import { RentalAdditionalInterest } from '../../Components/RentalAdditionalInterest';
import { PolicyRentersAdditionalInterest } from '../../../component-config';

const BoostAdditionalInterestScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Additional Interest'>) => {
  const [errorText, setErrorText] = useState<string>();
  const [state, setState] = useState<PolicyRentersAdditionalInterest>();
  const [createAdditionalInterest] = useMutation<
    CreateRentersAdditionalInterest,
    CreateRentersAdditionalInterestVariables
  >(CREATE_RENTERS_ADDITIONAL_INTEREST);

  const width = isFullWeb() ? 450 : 300;
  const TITLE = 'Additional Interest';

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: TITLE,
      headerBackTitleVisible: false,
      headerShown: isNativeOrMobileWeb(),
      gestureEnabled: false,
    });
  }, [navigation]);

  const onSubmit = async () => {
    await createAdditionalInterest({ variables: { input: state } });

    setErrorText('Additional Interest Generated');
  };

  return (
    <ContentUnified title={TITLE} ChildrenWrapper={ContentCenterScroll}>
      <VerticalCenter
        style={{
          width,
        }}>
        {isFullWeb() && (
          <>
            <Spacer y={7} />

            <LargeText>Additional Interest (Optional)</LargeText>
          </>
        )}

        <Spacer y={2} />

        {errorText && (
          <>
            <Spacer y={2.5} />
            <ErrorText>{errorText}</ErrorText>
          </>
        )}

        <Spacer y={2.5} />

        <RentalAdditionalInterest
          width={width}
          answerInputs={state}
          setAnswerInputs={setState}
        />

        <Spacer y={1} />

        <MediumText style={{ textAlign: 'center' }}>
          Examples include your apartment’s management company.
        </MediumText>

        <Spacer y={2.5} />

        <WaffleButtonAsync onPress={onSubmit} name={'Add'} />

        {isNativeOrMobileWeb() && <Spacer y={12} />}
      </VerticalCenter>
    </ContentUnified>
  );
};

export default BoostAdditionalInterestScreen;
