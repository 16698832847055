import React from 'react';
import styled from 'styled-components/native';

import { PlainView } from './Components/SimpleComponents';
import { HorizontalSpread } from './Components/SimpleLayouts';
import { Updating } from './Updating';
import WaffleText from './Components/WaffleText';
import { priceFmt } from './Helper/MiscFunctions';

import { IsSmallScreen } from './Helper/DeviceHelper';
import { PolicyLongFrequencyLabel } from './Helper/PolicyHelper';
import { Platform, TextStyle, ViewStyle } from 'react-native';
import { Policy, PolicyItems } from '../component-config';
// import { Fade, Placeholder, PlaceholderLine } from 'rn-placeholder';

const ItemText = styled(WaffleText)`
  font-size: 16px;
`;

const TotalText = styled(WaffleText)`
  font-size: 18px;
  font-weight: 500;
`;

type PolicyItemLinesProps = {
  lines: PolicyItems;
  updating: boolean;
  textStyle: TextStyle | TextStyle[];
};

const PolicyItemLines = ({
  lines,
  updating,
  textStyle,
}: PolicyItemLinesProps) => {
  return (
    <>
      {lines.map((item, idx) => {
        if (updating && item?.price === 0) {
          return <></>;
          // return (
          //   <Placeholder Animation={Fade}>
          //     {/* @ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
          //     <PlaceholderLine width={'100%'}/>
          //   </Placeholder>
          // );
        }
        if (item?.price > 0) {
          return (
            <HorizontalSpread key={idx}>
              <ItemText style={textStyle}>{item.description}</ItemText>
              <ItemText style={textStyle}>${priceFmt(item.price)}</ItemText>
            </HorizontalSpread>
          );
        }
        return null;
      })}
    </>
  );
};

type PolicyItemsDisplayProps = {
  policy: Policy;
  showTotal?: boolean;
  updating?: boolean;
  textStyle?: TextStyle | TextStyle[];
  totalTextStyle?: TextStyle;
  viewStyle?: ViewStyle;
};

// todo : make this a generic table?
export const PolicyItemsDisplay = ({
  policy,
  showTotal = true,
  updating = false,
  textStyle = {},
  viewStyle,
  totalTextStyle,
}: PolicyItemsDisplayProps) => (
  <>
    <PlainView
      style={[
        {
          width: IsSmallScreen() ? 220 : 269,
        },
        Platform.OS !== 'web' && {
          marginBottom: 10,
          marginTop: 10,
        },
        viewStyle,
      ]}>
      <PolicyItemLines
        lines={policy.items}
        updating={updating}
        textStyle={textStyle}
      />

      {showTotal && (
        <HorizontalSpread>
          <TotalText style={[textStyle, totalTextStyle]}>
            Total {PolicyLongFrequencyLabel(policy)}
          </TotalText>

          <TotalText style={[textStyle, totalTextStyle]}>
            ${priceFmt(policy?.price)}
          </TotalText>
        </HorizontalSpread>
      )}
    </PlainView>

    <Updating updating={updating} viewStyle={{ bottom: 0, left: 0 }} />
  </>
);
