import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import styled from 'styled-components/native';
import * as yup from 'yup';
import WaffleCreateHeader from '../Components/WaffleCreateHeader';
import WaffleTextInput from '../Components/WaffleTextInput';
import WaffleHalfTextInput from '../Components/WaffleHalfTextInput';
import {
  GET_LOCAL_USER,
  GET_LOCAL_USER_ID,
  VALIDATE_USER_ADDRESS,
} from '../GraphQL/Waffle/Queries';
import WaffleText from '../Components/WaffleText';
import { PlainView, Spacer } from '../Components/SimpleComponents';
import { ErrorText } from '../Components/ErrorText';
import {
  FirstNameInput,
  firstNameValidator,
} from '../Components/CreateAccount/FirstNameInput';
import {
  LastNameInput,
  lastNameValidator,
} from '../Components/CreateAccount/LastNameInput';
import { CityInput } from '../Components/CreateAccount/CityInput';
import { StateInput } from '../Components/CreateAccount/StateInput';
import { PostalInput } from '../Components/CreateAccount/PostalInput';
import { cloneObj } from '../Helper/MiscFunctions';
import {
  addressValidators,
  AutoCompleteAddress,
  validateAddressMessage,
} from '../Components/CreateAccount/AutoCompleteAddress';
import { StateCodes } from '../Constants/States';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import AuthenticationService from '../Helper/AuthenticationService';
import { KeyboardViewMaybe } from '../Components/KeyboardViewMaybe';
import DateInput, { maxBirthDate, minBirthDate } from '../Components/DateInput';
import { VerticalPacked } from '../Components/SimpleLayouts';
import style from '../Constants/Style';
import PhoneInput, {
  PhoneValidator,
} from '../Components/CreateAccount/PhoneInput';
import { hasGeo } from '../Helper/FeatureFlags';
import GeoService from '../Helper/GeoService';
import { Logger } from '../Helper/Logger';
import { StackScreenProps } from '@react-navigation/stack';
import {
  DisclosuresScreenName,
  LoginScreenName,
  RootStackParamList,
  SuggestedCoverageScreenName,
} from '../../screen-config';
import {
  GetLocalUserId,
  LocalUser,
  ValidateUserAddress,
  ValidateUserAddress_validateAddress_suggestions,
  ValidateUserAddressVariables,
} from '../../../operation-result-types';
import { Address, RefreshUserState, UserState } from '../Helper/UserHelpers';
import { SuggestedAddressPopup } from '../Components/SuggestedAddressPopup';
import {
  USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY,
  USER_STATE_SUGGESTED_COVERAGE,
} from '../Helper/NavigationService';

const ReferralText = styled(WaffleText)`
  height: 30px;
  width: 300px;
  color: black;
  font-size: 21px;
  line-height: 30px;
  text-align: center;
`;

const Horizontal = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const dateOfBirthValidator = yup
  .string()
  .matches(/\d{4}-\d{2}-\d{2}/)
  .required(); // todo : validate date

const validatorValueMessage = (validator: any, value: string, label: string) =>
  validator.isValidSync(value) ? null : `Please enter your ${label}`;

const validateForm = (state: UserState) => {
  const errors: UserState = {
    firstName: validatorValueMessage(
      firstNameValidator,
      state.firstName,
      'first name'
    ),
    lastName: validatorValueMessage(
      lastNameValidator,
      state.lastName,
      'last name'
    ),
    dateOfBirth: validatorValueMessage(
      dateOfBirthValidator,
      state.dateOfBirth,
      'birth date'
    ),
    address: validateAddressMessage(state),
    phone: validatorValueMessage(PhoneValidator, state.phone, 'phone number'),
  };

  return errors;
};

const schema = yup.object().shape({
  firstName: firstNameValidator,
  lastName: lastNameValidator,
  dateOfBirth: dateOfBirthValidator,
  phone: PhoneValidator,
  referralCode: yup.string(),
  ...addressValidators,
});

const Container =
  Platform.OS === 'web'
    ? (props) => <ScrollView style={style.fullHeight} {...props} />
    : KeyboardViewMaybe;

const CreateAccountScreenCont = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Create Account Cont'>) => {
  const client = useApolloClient();
  const {
    data: { userId },
  } = useQuery<GetLocalUserId>(GET_LOCAL_USER_ID);
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const [submissionError, setSubmissionError] = useState();
  const [state, setState] = useState<UserState>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    address: undefined,
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    referralCode: '',
    validatedAddress: true,
    completedAddress: {},
    rawAddress: '',
  });
  const [checkAddress] = useMutation<
    ValidateUserAddress,
    ValidateUserAddressVariables
  >(VALIDATE_USER_ADDRESS);
  const [errors, setErrors] = useState<UserState>({});
  const [valid, setValid] = useState(false);
  const [hideResults, setHideResults] = useState(true);
  const [addressSuggestion, setAddressSuggestion] =
    useState<ValidateUserAddress_validateAddress_suggestions>();
  const width = 300;

  useEffect(() => {
    (async () => {
      Logger('CreateAccountScreenCont: refresh user state');

      if (user?.userState === USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY) {
        RefreshUserState(client).then((u) => {
          if (u) {
            setState({
              ...state,
              firstName: u.firstName,
              lastName: u.lastName,
              dateOfBirth: u.dateOfBirth,
              address: u.address,
              address2: u.address2,
              city: u.city,
              state: u.state,
              zip: u.zip,
            });
          }
        });
      }
    })();
  }, [client]);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
      headerBackTitleVisible: false,
    });
  }, [navigation]);

  const onChange = (obj: UserState) => {
    // wrap in useCallback
    const newState = { ...state, ...obj };
    const isValid =
      schema.isValidSync(newState) && !!StateCodes[newState.state];

    setState(newState);
    setValid(isValid);

    // Logger(`onChange: state=${JSON.stringify(state)} newState=${JSON.stringify(newState)} isValid=${isValid}`);

    return newState;
  };

  const onAddressFieldChange = (obj: UserState) =>
    onChange({
      ...obj,
      validatedAddress: false,
    });

  const setAddress = (address: Address) => {
    return onChange({
      address: `${address.houseNumber} ${address.street}`,
      city: address.city,
      state: address.state,
      zip: address.postalCode,
      validatedAddress: true,
      completedAddress: cloneObj(address),
      rawAddress: '',
    });
  };

  const validateValue = (
    field: keyof UserState,
    value: string,
    validator: any,
    label: string
  ) =>
    setErrors({
      ...errors,
      [field]: validatorValueMessage(validator, value, label),
    });

  const validateAddress = (st: UserState) =>
    setErrors({ ...errors, address: validateAddressMessage(st) });

  const isValidAsync = async () => {
    setErrors(validateForm(state));

    if (!valid) {
      return false;
    }

    const {
      data: { validateAddress },
    } = await checkAddress({
      variables: {
        input: {
          address: state.address,
          address2: state.address2,
          city: state.city,
          state: state.state,
          zip: state.zip,
        },
      },
    });

    if (!validateAddress.isValid) {
      if (validateAddress?.suggestions?.length > 0) {
        setAddressSuggestion(validateAddress.suggestions[0]);
      } else {
        setErrors((e) => ({
          ...e,
          address:
            validateAddress?.message ??
            'The address does not appear to be valid',
        }));
      }
      // Logger(`addresses: ${JSON.stringify(validateAddress)}`);

      return false;
    }

    return true;
  };

  const onPick = async (address) => {
    const newState = { ...state, ...address };

    // User performed a correction, so update state, clear errors, and proceed
    await setErrors({});
    await setState(newState);
    await setAddressSuggestion(undefined);

    const u = await AuthenticationService.UpdateUserDetails(
      client,
      userId,
      state
    );

    if (u?.userState === USER_STATE_SUGGESTED_COVERAGE) {
      navigation.navigate(SuggestedCoverageScreenName);
    } else {
      navigation.navigate(DisclosuresScreenName);
    }
  };

  useEffect(() => {
    if (hasGeo()) {
      GeoService.startServiceIfNecessary(client).then((_) =>
        Logger('Done starting GeoService')
      );
    }
  }, []);

  // Logger(
  //   `onChange: state=${JSON.stringify(state)} isValid=${valid} state=${
  //     StateCodes[state.state]
  //   }`
  // );

  const onSubmitAsync = async () => {
    if (await isValidAsync()) {
      const u = await AuthenticationService.UpdateUserDetails(
        client,
        userId,
        state
      );

      if (u?.userState === USER_STATE_SUGGESTED_COVERAGE) {
        navigation.navigate(SuggestedCoverageScreenName);
      } else {
        navigation.navigate(DisclosuresScreenName);
      }
    }
  };

  return (
    <Container>
      <WaffleCreateHeader
        title={'Create Account'}
        subtitle={'Enter your information below'}
      />

      <VerticalPacked style={[style.fullWidth]}>
        <Spacer y={20} />

        <ErrorText>{submissionError}</ErrorText>

        <Horizontal style={{ width, alignItems: 'flex-start' }}>
          <FirstNameInput
            onChangeText={(firstName: string) => onChange({ firstName })}
            value={state.firstName}
            errorFooterText={errors.firstName}
            onValidate={(value) =>
              validateValue(
                'firstName',
                value,
                firstNameValidator,
                'first name'
              )
            }
          />

          <Spacer x={1} />

          <LastNameInput
            onChangeText={(lastName: string) => onChange({ lastName })}
            value={state.lastName}
            errorFooterText={errors.lastName}
            onValidate={(value) =>
              validateValue('lastName', value, lastNameValidator, 'last name')
            }
          />
        </Horizontal>

        <Spacer y={1} />

        <Horizontal>
          <DateInput
            value={state.dateOfBirth}
            placeholder={'Birth date'}
            onChangeText={(dateOfBirth: string) => onChange({ dateOfBirth })}
            containerStyle={{ width }}
            errorFooterText={errors.dateOfBirth}
            onValidate={(value) =>
              validateValue(
                'dateOfBirth',
                value,
                dateOfBirthValidator,
                'birth date'
              )
            }
            initialDate={maxBirthDate()}
            minimumDate={minBirthDate()}
            maximumDate={maxBirthDate()}
          />
        </Horizontal>

        <Spacer y={3} />

        <PlainView style={{ width }}>
          <AutoCompleteAddress
            hideResults={hideResults}
            value={state.address}
            onChoose={(address: any) => validateAddress(setAddress(address))}
            onFocus={() => setHideResults(false)}
            onBlur={() => {
              setTimeout(() => setHideResults(true), 500); // delay on hiding results when we lose focus
              validateAddress(state);
            }}
            onChangeText={(rawAddress: string) =>
              onAddressFieldChange({ address: rawAddress, rawAddress })
            }
          />

          <Spacer y={1} />

          <Horizontal>
            <WaffleHalfTextInput
              placeholder={'Suite, apartment, etc (opt)'}
              value={state.address2}
              otherProps={{
                autoCompleteType: 'address-line2',
                textContentType: 'streetAddressLine2',
                editable: true,
              }}
              // this isn't a standard address change, as it doesn't taint
              // the address.
              onChangeText={(address2: string) => onChange({ address2 })}
              onBlur={() => validateAddress(state)}
            />
          </Horizontal>

          <Spacer y={1} />

          <Horizontal>
            <CityInput
              value={state.city}
              onChangeText={(city: string) => onAddressFieldChange({ city })}
              otherProps={{ editable: true }}
              onBlur={() => validateAddress(state)}
            />

            <Spacer x={1} />

            <StateInput
              value={state.state}
              onChangeText={(state: string) => onAddressFieldChange({ state })}
              otherProps={{ editable: true }}
              onBlur={() => validateAddress(state)}
            />
          </Horizontal>

          <Spacer y={1} />

          <Horizontal>
            <PostalInput
              value={state.zip}
              onChangeText={(zip: string) => onAddressFieldChange({ zip })}
              otherProps={{ editable: true }}
              errorFooterText={errors.address} // Yes, we consolidate all validation under "address"
              onBlur={() => validateAddress(state)}
            />
          </Horizontal>
        </PlainView>

        <Spacer y={2} />

        <PhoneInput
          value={state.phone}
          onChangeText={(phone: string) => onChange({ phone })}
          errorFooterText={errors.phone}
          onValidate={(value) =>
            validateValue('phone', value, PhoneValidator, 'phone number')
          }
          containerStyle={{ width }}
        />

        <Spacer y={2} />

        <View style={{ alignItems: 'center' }}>
          <ReferralText>Do you have a referral code?</ReferralText>
        </View>

        <Spacer y={2} />

        <WaffleTextInput
          label={'Referral Code'}
          placeholder={'Referral Code'}
          onChangeText={(referralCode: string) => onChange({ referralCode })}
          containerStyle={{ width }}
          optional={true}
        />

        <Spacer y={3} />

        <View style={{ alignItems: 'center' }}>
          <WaffleButtonAsync onPress={onSubmitAsync} name={'Sign Up'} />

          <Spacer y={1} />

          {user?.userState !== USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY && (
            <WaffleText
              onPress={async () => {
                // todo : clear user state?
                navigation.navigate(LoginScreenName);
              }}>
              Have an account? Login
            </WaffleText>
          )}
        </View>

        <SuggestedAddressPopup
          address={state}
          suggestion={addressSuggestion}
          onDismiss={() => setAddressSuggestion(null)}
          onPick={onPick}
        />
        <Spacer y={3} />
      </VerticalPacked>
    </Container>
  );
};

export default CreateAccountScreenCont;
