import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import WaffleChat from '../Components/WaffleChat';
import {
  GET_ALL_MESSAGES,
  GET_LOCAL_USER_ID,
  INSERT_MESSAGE,
} from '../GraphQL/Waffle/Queries';
import { processMessages } from '../Components/Chat/ChatHelpers';
import { Logger } from '../Helper/Logger';
import {
  ContentUnified,
  FooterSpacer,
  OnlyBlock,
} from '../Components/ContentShared';
import { LoadingUnified } from '../Components/LoadingUnified';
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack';
import { HomeScreenName, RootStackParamList } from '../../screen-config';
import {
  GetAllMessages,
  GetAllMessagesVariables,
  GetLocalUserId,
  InsertMessage,
  InsertMessageVariables,
} from '../../../operation-result-types';
import { isNativeOrMobileWeb } from '../Helper/DeviceHelper';

const title = 'Make a claim';

const doComplete = async (insertOnly: any, username: string) => {
  await insertOnly({
    variables: {
      message: {
        username,
        party: username,
        text: 'Done',
        action: 'COMPLETE',
      },
    },
  });
};

const onSendText = async (
  insertMessage: any,
  username: string,
  text: string,
  navigation: StackNavigationProp<RootStackParamList>
) => {
  const isDone = false; // text === 'Done';
  const action = isDone ? 'COMPLETE' : null;
  await insertMessage({
    variables: {
      message: {
        username,
        text,
        party: username,
        action,
      },
    },
  });
  if (isDone) {
    navigation.navigate(HomeScreenName);
  }
};

const ClaimChatScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Claim Chat'>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isTyping, setIsTyping] = useState(false);
  const {
    data: { userId },
  } = useQuery<GetLocalUserId>(GET_LOCAL_USER_ID);
  // Return values to consider:
  // - subscribeToMore
  // - startPolling, stopPolling
  // if allMessages is empty, have it send an empty message?  and GET_ALL_MESSAGES
  // filters those out?  { _is_null: false }
  const {
    data: allMessages,
    loading,
    error,
  } = useQuery<GetAllMessages, GetAllMessagesVariables>(GET_ALL_MESSAGES, {
    variables: {
      last_received_id: -1,
      last_received_ts: '2019-05-14T00:00:00+00:00',
    },
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });
  const [insertMessage] = useMutation<InsertMessage, InsertMessageVariables>(
    INSERT_MESSAGE,
    {
      refetchQueries: () => {
        return [
          {
            query: GET_ALL_MESSAGES,
            variables: {
              last_received_id: -1,
              last_received_ts: '2019-05-14T00:00:00+00:00',
            },
          },
        ];
      },
    }
  );
  const [insertOnly] = useMutation<InsertMessage, InsertMessageVariables>(
    INSERT_MESSAGE,
    {
      ignoreResults: true,
    }
  );
  const messages =
    typeof allMessages !== 'undefined' && allMessages.listCurrentMessages
      ? allMessages.listCurrentMessages
          .map((m: any) => ({
            _id: m.id,
            text: m.text,
            choices: m.choices,
            image: m?.attachment_url?.endsWith('.jpg')
              ? m.attachment_url
              : undefined,
            video: m?.attachment_url?.endsWith('.mp4')
              ? m.attachment_url
              : undefined,
            createdAt: m.timestamp,
            action: m.action,
            user: { _id: m.party, name: m.party },
          }))
          .reverse()
      : [];

  if (messages?.length > 0 && messages[0]?.user?._id === 'waffle') {
    const recent = messages[0];
    if (recent?.action === 'wax on') {
      Logger('wax on, wax off');
      navigation.navigate(HomeScreenName);
    }
    if (recent?.action === 'DONE') {
      navigation.navigate(HomeScreenName);
    }
  }
  const lastActionOrQuickReply =
    !!allMessages?.listCurrentMessages?.[0]?.action ||
    allMessages?.listCurrentMessages?.[0]?.choices.length > 0;

  useLayoutEffect(() => {
    navigation.setOptions({
      title,
      headerShown: isNativeOrMobileWeb(),
      headerBackTitleVisible: false,
    });
  }, [navigation]);

  // No messages yet, so start the chat.
  useEffect(() => {
    Logger(
      `ClaimsChat.useEffect: ${JSON.stringify(
        messages?.length
      )} loading: ${loading}`
    );
    if (!loading && messages?.length === 0) {
      insertOnly({
        variables: {
          message: {
            username: userId,
            party: userId,
            text: 'Starting claim...',
            action: 'START_CLAIM',
          },
        },
      });
    }
  }, [messages]);

  if (loading || error) {
    return <LoadingUnified title={title} errors={error?.message} />;
  }

  return (
    <ContentUnified title={title} ChildrenWrapper={OnlyBlock}>
      <WaffleChat
        messages={processMessages(messages)}
        onSend={async (m) =>
          await onSendText(
            insertMessage,
            m[0].user._id.toString(),
            m[0].text,
            navigation
          )
        }
        onQuickReply={async (qr) =>
          await onSendText(
            insertMessage,
            userId,
            qr.map((v) => v.value)[0],
            navigation
          )
        }
        userId={userId}
        doComplete={async () => await doComplete(insertOnly, userId)}
        isTyping={isTyping}
        keyboardShouldPersistTaps={'never'}
        textInputProps={{
          // We don't autofocus on text entry box, since first question out of gate is a quick reply.
          autoFocus: false,
          editable: !lastActionOrQuickReply,
        }}
      />

      <FooterSpacer />
    </ContentUnified>
  );
};

export default ClaimChatScreen;
