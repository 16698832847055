import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components/native';
import WaffleText from '../Components/WaffleText';
import { Packed, VerticalCenter } from '../Components/SimpleLayouts';
import { ContentUnified } from '../Components/ContentShared';
import { BlueHeaderImage } from '../Components/HeaderNavTitle.web';
import { StackScreenProps } from '@react-navigation/stack';
import { HomeScreenName, RootStackParamList } from '../../screen-config';
import { isNativeOrMobileWeb } from '../Helper/DeviceHelper';

const Text = styled(WaffleText)`
  width: 288px;
  color: #1e2368;
  font-size: 18px;
  letter-spacing: 0.22px;
  line-height: 23px;
`;

const TitleText = styled(WaffleText)`
  height: 70px;
  width: 303px;
  color: #1e2368;
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.39px;
  line-height: 35px;
  margin-top: 17px;
  margin-bottom: 17px;
`;

// const PlainView = styled.View`
//   flex: 1;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: stretch;
//   background-color: #fff;
// `;
//
// const TextInput = styled.TextInput`
//   height: 45px;
//   width: 100%;
//   border-radius: 28px;
//   background-color: #fff;
//   border: 1px solid #dedede;
//   color: #4a4a4a;
//   font-family: ${WaffleTextFamily};
//   font-size: 18px;
//   font-weight: 300;
//   line-height: 26px;
//   padding-left: 21px;
//   margin-top: 12px;
//   margin-bottom: 12px;
//   shadow-color: rgba(83, 69, 69, 0.17);
//   shadow-offset: 0px 5px;
//   shadow-opacity: 1;
// `;
//
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const FullWidth = styled.View`
//   width: 100%;
// `;
//
// const View = styled.View``;
//
// const KeyboardAvoidingView = styled.KeyboardAvoidingView`
//   margin: 0px 23px 75px;
//   flex: 1;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
// `;
//
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const Horizontal = styled.View`
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
// `;
//
// const GiftBackground = styled.Image`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 607px;
//   z-index: -1;
// `;
//
// const ImageCheck = styled.Image`
//   height: 122px;
//   width: 122px;
// `;
//
// const SentText = styled(WaffleText)`
//   margin-top: 30px;
//   height: 42.36px;
//   width: 296px;
//   font-size: 25px;
//   text-align: center;
// `;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const Input = (props: any) => (
//   <TextInput placeholderColor={'#AFAFAF'} {...props} />
// );

const GiftScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Gift'>) => {
  const [popup, setPopup] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, setName] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [email, setEmail] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [amount, setAmount] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [createGiftCard] = useMutation(CREATE_GIFT_CARD);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Protect Your Loved Ones',
      headerShown: isNativeOrMobileWeb(),
      headerBackTitleVisible: false,
    });
  }, [navigation]);

  if (popup) {
    setTimeout(() => {
      setPopup(false);
      setTimeout(async () => navigation.navigate(HomeScreenName), 500);
    }, 1500);
  }

  /*
              At Waffle, we want you to protect those you love the most. So we've
            created the gift card for insurance (because nothing says I love you
            like insurance).

        <View style={{ width: '100%' }}>
          <Input
            placeholder={'Name'}
            style={{ marginTop: 0 }}
            onChangeText={v => setName(v)}
          />

          <Input
            placeholder={'Email'}
            keyboardType={'email-address'}
            autoCapitalize={'none'}
            onChangeText={v => setEmail(v)}
          />

          <FullWidth>
            <Horizontal>
              <Input
                placeholder={'$ Amount'}
                style={{ width: 151, height: 45 }}
                keyboardType={'number-pad'}
                onChangeText={v => setAmount(v)}
              />

              <WaffleButton
                disabled={name === '' || email === '' || amount === ''}
                name={'Send'}
                style={{
                  width: 151,
                  height: 45,
                  shadowColor: 'rgba(83, 69, 69, 0.17)',
                  shadowOffset: { width: 0, height: 5 },
                  shadowOpacity: 1,
                  shadowBlur: 30,
                }}
                onPress={async () => {
                  setPopup(true);
                  await createGiftCard({
                    variables: {
                      name,
                      email,
                      amount,
                    },
                  });
                }}
              />
            </Horizontal>
          </FullWidth>
        </View>

   */
  return (
    <ContentUnified
      title={'Protect your friends & family'}
      titleStyle={{ top: 18 }}
      image={BlueHeaderImage}>
      <VerticalCenter style={{ height: '100%' }}>
        <Packed>
          <TitleText>The gift that keeps on giving.</TitleText>

          <Text>
            At Waffle, we want to protect those you love the most. So we are
            creating the gift card for insurance. We will let you know as soon
            as it is launched.
          </Text>
        </Packed>
      </VerticalCenter>
    </ContentUnified>
  );
};

/*
        <Popup key={'popup'} visible={popup} noClose={true}>
          <View style={{ height: 332 }}>
            <VerticalCenter>
              <ImageCheck
                source={require('../../assets/elements/step-check.png')}
              />
              <SentText>Your gift card has been sent!</SentText>
            </VerticalCenter>
          </View>
        </Popup>

        <KeyboardAvoidingView behavior={'padding'} keyboardVerticalOffset={80}>
        </KeyboardAvoidingView>

<Input placeholder={'Relationship'}/>

<Input placeholder={'What do you want to protect?'}/>
*/

export default GiftScreen;
