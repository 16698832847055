import { useApolloClient } from '@apollo/client';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import * as yup from 'yup';
import WaffleCreateHeader from '../Components/WaffleCreateHeader';
import PasswordInput from '../Components/PasswordInput';
import { Spacer } from '../Components/SimpleComponents';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import { useFocusEffect } from '@react-navigation/native';
import AuthenticationService from '../Helper/AuthenticationService';
import { ErrorDisplay } from '../Components/ErrorDisplay';
import { HorizontalPacked, VerticalCenter } from '../Components/SimpleLayouts';
import style from '../Constants/Style';
import { KeyboardViewMaybe } from '../Components/KeyboardViewMaybe';
import { isFullWeb, IsSmallScreen } from '../Helper/DeviceHelper';
import { StackScreenProps } from '@react-navigation/stack';
import {
  CreateAccountConfirmScreenName,
  RootStackParamList,
} from '../../screen-config';
import { Platform } from 'react-native';
import { FetchGuestToken } from '../Helper/UserHelpers';
import { ParseJwt } from '../Helper/MiscFunctions';
import { useAuthContext } from './AuthProvider';
import { Login } from '../Helper/AuthHelper';
import { BigText, LargeText } from '../Components/WaffleText';
import { GreenHeaderImage } from '../Components/HeaderNavTitle.web';
import { ContentUnified } from '../Components/ContentShared';
import {
  ContainsExternalExitProduct,
  ProductLife,
} from '../Constants/Products';
import { PartnerExitNavigation } from '../Components/DesignPolicy/SuggestedCoverageShared';
import { useUserTracking } from '../../lib/user-tracking/UserTrackingProvider';

const schema = yup.object().shape({
  email: yup.string().required('Email is required').email(),
  password: yup.string().min(8).required(),
});

interface State {
  email?: string;
  password?: string;
}

const isValid = (state: State) => schema.isValidSync(state);

const CreateLoginScreen = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, 'Create Login'>) => {
  const client = useApolloClient();
  const userTracking = useUserTracking();
  const initialEmail = route.params?.initialEmail ?? '';
  const products = route.params?.products;
  const [state, setState] = useState<State>({});
  const [error, setError] = useState('');
  const { setIsSignedIn } = useAuthContext();
  const product = products?.length > 0 ? products[0] : 'Waffle';
  const message =
    product === ProductLife
      ? 'To access your policy documents and add further products, please create an account.'
      : 'To access your policy documents, submit a claim, and add further products, please create an account.';

  const onChangeText = (key: keyof State, text: string) =>
    setState({ ...state, [key]: text });

  const onSignUpAsync = async () => {
    try {
      const token = await FetchGuestToken();
      const obj = ParseJwt(token);

      const { userConfirmed } = await AuthenticationService.SignUp(
        client,
        state.email,
        state.password,
        { 'custom:user_id': obj?.sub }
      );

      userTracking?.updateUserTraits({
        createdAt: new Date().toISOString(),
      });

      if (userConfirmed) {
        await Login(
          navigation,
          client,
          state.email,
          state.password,
          setIsSignedIn
        );

        if (ContainsExternalExitProduct(products)) {
          await PartnerExitNavigation(navigation, client, products);
        }
      } else {
        navigation.navigate(CreateAccountConfirmScreenName, {
          email: state.email,
        });
      }
    } catch (e) {
      setError(e);
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  useFocusEffect(
    useCallback(() => onChangeText('email', initialEmail), [initialEmail])
  );

  const TITLE = 'Create Account';

  return (
    <ContentUnified title={TITLE} image={GreenHeaderImage} addingProduct={true}>
      <KeyboardViewMaybe>
        {Platform.OS !== 'web' && <WaffleCreateHeader title={TITLE} />}

        <VerticalCenter style={[style.fullWidth, style.fullHeight]}>
          {IsSmallScreen() && <Spacer y={7} />}

          <BigText
            style={{ maxWidth: isFullWeb() ? 500 : 335, textAlign: 'center' }}>
            Congratulations! You&apos;re Protected with {product} Insurance!
          </BigText>

          <Spacer y={3} />

          <ErrorDisplay error={error} />

          <LargeText style={{ width: 300, textAlign: 'center' }}>
            {message}
          </LargeText>

          <Spacer y={2.5} />

          <PasswordInput
            label={'Password'}
            onChangeText={(text: string) => onChangeText('password', text)}
            otherProps={{
              autoCompleteType: 'new-password',
              textContentType: 'newPassword',
              testID: 'create-login-password-input',
            }}
            containerStyle={{ width: 300 }}
            footerText={'8 or more characters'}
          />

          <Spacer y={2.5} />

          <HorizontalPacked>
            <WaffleButtonAsync
              testID="create-login-submit-button"
              disabled={!isValid(state)}
              onPress={onSignUpAsync}
              name={'Next'}
            />
          </HorizontalPacked>
        </VerticalCenter>
      </KeyboardViewMaybe>
    </ContentUnified>
  );
};

export default CreateLoginScreen;
