import React from 'react';
import { PolicyProps } from '../../../component-config';
import { ProductCyber } from '../../Constants/Products';
import { VerticalCenter } from '../../Components/SimpleLayouts';
import { CardMobile, CardWeb, SectionTitle, WrappedView } from './Shared';
import { Spacer } from '../../Components/SimpleComponents';
import { isFullWeb } from '../../Helper/DeviceHelper';

const WhenTheseHappen = ({ policy }: PolicyProps) => {
  if (policy.productId === ProductCyber) {
    const Card = isFullWeb() ? CardWeb : CardMobile;
    const Wrapper = isFullWeb() ? WrappedView : React.Fragment;
    const width = isFullWeb() ? 250 : 350;

    return (
      <>
        <VerticalCenter>
          <SectionTitle>...when these happen:</SectionTitle>
        </VerticalCenter>

        <Spacer y={2} />

        <Wrapper>
          {policy?.product?.suggestedCoveragePlanHighlights.map(
            (highlight, idx) => (
              <Card
                key={idx}
                productId={policy.productId}
                title={highlight.title}
                description={highlight.description}
                width={width}
              />
            )
          )}
        </Wrapper>
      </>
    );
  }

  return null;
};

export default WhenTheseHappen;
