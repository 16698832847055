import styled from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';
import {
  HorizontalSpread,
  VerticalCenter,
} from '../../Components/SimpleLayouts';
import { WaffleLightGrey, White } from '../../Constants/Style';
import { View } from 'react-native';
import { isFullWeb } from '../../Helper/DeviceHelper';
import React from 'react';
import { ImageMaybe } from '../../Components/DesignPolicy/HighlightsAndRestrictions';
import { Spacer } from '../../Components/SimpleComponents';

export const SectionTitle = styled(WaffleText)`
  font-size: 35px;
  text-align: center;
`;

export const GreySection = styled(VerticalCenter)`
  width: 100%;
  background-color: ${WaffleLightGrey};
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const WhiteSection = styled(VerticalCenter)`
  width: 100%;
  background-color: ${White};
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const WrappedView = (props) => (
  <View
    style={[
      {
        // padding: 20,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        maxWidth: isFullWeb() ? 960 : 335,
      },
      isFullWeb() && {
        gap: 25,
      },
    ]}>
    {props.children}
  </View>
);

export const CardMobile = ({ productId, title, description, width }) => (
  <VerticalCenter style={{ width: '100%' }}>
    <HorizontalSpread
      style={{
        width,
        alignItems: 'flex-start',
        marginBottom: 30,
      }}>
      <ImageMaybe productId={productId} title={title} />

      <Spacer x={2.5} />

      <VerticalCenter style={{ width: width - 70 - 20 }}>
        <WaffleText
          style={{ fontSize: 18, fontWeight: '700', textAlign: 'center' }}>
          {title}
        </WaffleText>

        <Spacer y={1.5} />

        <WaffleText
          style={{
            fontSize: 13,
            fontWeight: '400',
            textAlign: 'center',
          }}>
          {description}
        </WaffleText>
      </VerticalCenter>
    </HorizontalSpread>
  </VerticalCenter>
);

export const CardWeb = ({ productId, title, description, width }) => (
  <VerticalCenter
    style={{
      width,
    }}>
    <ImageMaybe productId={productId} title={title} />

    <Spacer y={1.5} />

    <WaffleText
      style={{ fontSize: 18, fontWeight: '700', textAlign: 'center' }}>
      {title}
    </WaffleText>

    <Spacer y={1.5} />

    <WaffleText
      style={{
        fontSize: 13,
        fontWeight: '400',
        width: width - 15,
        textAlign: 'center',
      }}>
      {description}
    </WaffleText>
  </VerticalCenter>
);
