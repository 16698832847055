import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import {
  CardMobile,
  CardWeb,
  GreySection,
  SectionTitle,
  WrappedView,
} from './Shared';
import { VerticalCenter } from '../../Components/SimpleLayouts';
import { Spacer } from '../../Components/SimpleComponents';
import { SuggestedCoverageContext } from '../SuggestedCoverageScreen';
import { LocalUser } from '../../../../operation-result-types';
import { GET_LOCAL_USER } from '../../GraphQL/Waffle/Queries';
import {
  GetFullPolicy,
  ItemLabel,
  ItemLabelOrangeLink,
} from '../../Components/DesignPolicy/SuggestedCoverageShared';
import { White } from '../../Constants/Style';
import { TouchableWithoutFeedback } from 'react-native';
import { ShowWebLink } from '../../Components/WebViewPopupLink';
import { isFullWeb } from '../../Helper/DeviceHelper';

const PleaseNote = () => {
  const { state } = useContext(SuggestedCoverageContext);
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const policy = state.currentPolicy;
  const fullPolicy = GetFullPolicy(user, policy?.productId);

  // todo : fix undefined below.  make navigation
  return (
    <>
      {fullPolicy && (
        <VerticalCenter
          style={{
            backgroundColor: White,
            width: 346,
            padding: 16,
            borderRadius: 20,
          }}>
          <TouchableWithoutFeedback
            onPress={() => ShowWebLink(undefined, fullPolicy)}>
            <ItemLabel
              style={{ fontSize: 13, fontWeight: '400', textAlign: 'center' }}>
              Please note that the above is only a summary - to read the full
              policy for all details click{' '}
              <ItemLabelOrangeLink style={{ fontSize: 11, fontWeight: '400' }}>
                here
              </ItemLabelOrangeLink>
            </ItemLabel>
          </TouchableWithoutFeedback>
        </VerticalCenter>
      )}
    </>
  );
};

export const Exclusions = () => {
  const { state } = useContext(SuggestedCoverageContext);
  const policy = state.currentPolicy;
  const Card = isFullWeb() ? CardWeb : CardMobile;
  const Wrapper = isFullWeb() ? WrappedView : React.Fragment;
  const width = isFullWeb() ? 250 : 350;

  return (
    <GreySection>
      <VerticalCenter>
        <SectionTitle>What&apos;s not covered...</SectionTitle>
      </VerticalCenter>

      <Spacer y={2.5} />

      <Wrapper>
        {policy?.product?.suggestedCoverageExampleRestrictions.map(
          (restriction, idx) => (
            <Card
              key={idx}
              productId={policy.productId}
              title={restriction.title}
              description={restriction.description}
              width={width}
            />
          )
        )}
      </Wrapper>

      <Spacer y={2.5} />

      <PleaseNote />
    </GreySection>
  );
};
