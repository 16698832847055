import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import {
  RootStackParamList,
  UpdateGuestUserScreenName,
} from '../../../screen-config';
import { Spacer } from '../../Components/SimpleComponents';
import { PolicyExamples } from './PolicyExamples';
import { CyberQuoteSummary } from './CyberQuoteSummary';
import { CoverageEditor } from './CoverageEditor';
import { Exclusions } from './Exclusions';
import { PayOrQuestions } from './PayOrQuestions';
import {
  GetDraftUserPolicies,
  LocalUser,
  RepriceDraftPolicies,
} from '../../../../operation-result-types';
import {
  DRAFT_POLICIES,
  GET_LOCAL_USER,
  REPRICE_DRAFT_POLICIES,
} from '../../GraphQL/Waffle/Queries';
import {
  SuggestedCoverageContext,
  SuggestedCoverageState,
} from '../SuggestedCoverageScreen';
import { Animated, useWindowDimensions, View } from 'react-native';
import styles from '../../Constants/Style';
import { Logger } from '../../Helper/Logger';
import {
  extractPolicyProductMessages,
  onReFetch,
  SuggestedCoverageNextNavigation,
} from '../../Components/DesignPolicy/SuggestedCoverageShared';
import {
  IsIncompleteUser,
  RefreshUserState,
  UpdateUserStateOnServer,
} from '../../Helper/UserHelpers';
import { USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY } from '../../Helper/NavigationService';
import { useAuthContext } from '../AuthProvider';
import PriceButton from './PriceButton';
import { MediumText } from '../../Components/WaffleText';
import { useWaffleNavigationHeader } from '../../Components/NavHeader';

const Scroller = (props) => (
  <Animated.ScrollView
    showsVerticalScrollIndicator={false}
    showsHorizontalScrollIndicator={false}
    style={[styles.fullWidth, styles.fullHeightMinusHeader]}
    contentContainerStyle={styles.verticalPackedCenter}
    scrollEventThrottle={4}
    onScroll={props.onScroll}>
    {props.children}
  </Animated.ScrollView>
);

const CyberQuoteScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Product Chat'>) => {
  const client = useApolloClient();
  const { height } = useWindowDimensions();
  const { isGuest } = useAuthContext();
  const ref = useRef<View>(null);
  const priceY = useRef(5000);
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const scrollY = useRef(new Animated.Value(0)).current;
  const { data, loading } = useQuery<GetDraftUserPolicies>(DRAFT_POLICIES, {
    fetchPolicy: 'network-only',
  });
  const [reprice] = useMutation<RepriceDraftPolicies>(REPRICE_DRAFT_POLICIES);
  const [state, setState] = useState<SuggestedCoverageState>({
    tab: 0,
    policyCount: 0,
    updating: false,
    confirmPopup: false,
    updateMessages: {},
  });
  const policies = data?.draftUserPolicies ?? [];
  const policyCount = data?.draftUserPolicies?.length ?? 1;
  const currentPolicy = data?.draftUserPolicies?.[state.tab];
  const priceButtonOffset = height - 500;

  useWaffleNavigationHeader(navigation);

  const rePriceAndReFetch = () => {
    Logger(`DesignCoverageDetail: start initial pricing`);
    setState((st) => ({ ...st, updating: true }));

    reprice().then(({ data }) => {
      const updateMessages = data?.repriceDraftPolicies
        ? extractPolicyProductMessages(data.repriceDraftPolicies)
        : {};

      onReFetch(client).then(() => {
        setState((st) => ({ ...st, updateMessages, updating: false }));
        Logger(`DesignCoverageDetail: done pricing tab`);
      });
    });
  };

  const onContinue = async () => {
    // If user is guest, then make them fill out more information
    if (IsIncompleteUser(isGuest, user)) {
      await UpdateUserStateOnServer(
        client,
        USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY
      );

      await RefreshUserState(client);

      await navigation.navigate(UpdateGuestUserScreenName);

      return;
    }

    await SuggestedCoverageNextNavigation(
      navigation,
      client,
      isGuest,
      policies.map((p) => p.productId)
    );
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    setState((existing) => ({ ...existing, currentPolicy, policyCount }));

    if (currentPolicy?.price === 0 || !currentPolicy) {
      rePriceAndReFetch();
    }
  }, [
    currentPolicy,
    currentPolicy?.price,
    currentPolicy?.productId,
    policyCount,
    loading,
  ]);

  if (loading || !state.currentPolicy) {
    return null;
  }

  /*
      <ContentUnified
      titleStyle={{ alignItems: 'center' }}
      title={'Cyber Insurance'}
      showNav={false}
      addingProduct={true}
      ChildrenWrapper={Scroller}>
    </ContentUnified>

   */
  return (
    <Scroller
      onScroll={Animated.event(
        // scrollY = e.nativeEvent.contentOffset.y
        [{ nativeEvent: { contentOffset: { y: scrollY } } }],
        { useNativeDriver: true }
      )}>
      <SuggestedCoverageContext.Provider value={{ state, setState }}>
        <PriceButton
          prefixText={'Pay '}
          onPress={onContinue}
          style={{
            width: 312,
            height: 44,
            borderRadius: 5,
          }}
          positionStyle={{
            position: 'absolute',
            transform: [
              {
                translateY: scrollY.interpolate({
                  inputRange: [0, 250, 5000],
                  outputRange: [
                    priceY.current, // Start with position of View container.
                    250 + (height - 150 - 44), // Move to 150 above the bottom of screen and stay there
                    5000 + (height - 150 - 44),
                  ],
                  extrapolate: 'clamp',
                }),
              },
            ],
          }}
          animatedTextStyle={{
            fontSize: 24,
          }}
        />

        <Spacer y={5} />

        <CyberQuoteSummary />

        <PriceButton
          testID="cyber-quote-small-price-button"
          onPress={onContinue}
          style={{}}
          positionStyle={{}}
          animatedTextStyle={{}}
        />

        <Spacer y={5} />

        <View
          ref={(r) => (ref.current = r)}
          onLayout={({ nativeEvent }) => {
            if (ref.current) {
              ref.current.measure((x, y, width, height, pageX, pageY) => {
                priceY.current = y;
                Logger(
                  `Measure: x=${x} y=${y} width=${width} height=${height} pageX=${pageX} pageY=${pageY}`
                );
              });
            }
          }}>
          <Spacer y={5} />
        </View>

        <Spacer y={2.5} />

        <MediumText style={{ fontWeight: '400', color: '#919191' }}>
          No hidden fees. Cancel anytime.
        </MediumText>

        <Spacer y={5} />

        <CoverageEditor />

        <PolicyExamples />

        <Exclusions />

        <PayOrQuestions />

        <Spacer y={20} />
      </SuggestedCoverageContext.Provider>
    </Scroller>
  );
};

export default CyberQuoteScreen;
