import React, { useContext, useState } from 'react';
import { SuggestedCoverageContext } from '../SuggestedCoverageScreen';
import { Animated, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';
import {
  WaffleOrange,
  WaffleOrangeDesaturated,
  White,
} from '../../Constants/Style';
import { Horizontal, VerticalAround } from '../../Components/SimpleLayouts';
import { priceFmt } from '../../Helper/MiscFunctions';
import { PolicyShortFrequencyLabel } from '../../Helper/PolicyHelper';
import { PlainView } from '../../Components/SimpleComponents';
import SpinnerBlack from '../../Components/SpinnerBlack';
import { WaffleTextFamily } from '../../Components/WaffleText';
import { NormalButtonWidth } from '../../Helper/DeviceHelper';

const AnimatedText = ({ style, children }) => (
  <Animated.Text
    style={[
      {
        fontFamily: WaffleTextFamily,
        color: White,
        fontSize: 15,
        fontWeight: '500',
        // letter-spacing: 0.6px;
        // lineHeight: 23,
        textAlign: 'center',
        // text-transform: uppercase;
      },
      style,
    ]}>
    {children}
  </Animated.Text>
);

type PriceButtonProps = {
  animatedTextStyle: Animated.WithAnimatedObject<TextStyle>;
  onPress: () => Promise<void>;
  positionStyle: Animated.WithAnimatedObject<ViewStyle>;
  prefixText?: string;
  style: ViewStyle;
  testID?: string;
};

const PriceButton = ({
  animatedTextStyle,
  onPress,
  positionStyle,
  prefixText = '',
  style,
  testID = 'price-button',
}: PriceButtonProps) => {
  const [pressed, setPressed] = useState(false);
  const { state } = useContext(SuggestedCoverageContext);
  const policy = state.currentPolicy;

  if (!policy) {
    return null;
  }

  const doOnPress = async () => {
    if (!pressed) {
      await setPressed(true);

      try {
        await onPress();
      } finally {
        setPressed(false);
      }
    }
  };

  return (
    <Animated.View
      style={[
        {
          shadowRadius: 32,
          borderRadius: 32,
          shadowOffset: { width: 0, height: 4 },
          shadowColor: 'rgba(108,62,12,0.5)',
          shadowOpacity: 1,
          elevation: 5,
          zIndex: 99,
          backgroundColor: White,
        },
        style,
        positionStyle,
      ]}>
      <TouchableOpacity testID={testID} activeOpacity={0.5} onPress={doOnPress}>
        <Animated.View
          style={[
            {
              borderRadius: 32,
              width: NormalButtonWidth(),
              height: 152,
              justifyContent: 'center',
              backgroundColor: pressed ? WaffleOrangeDesaturated : WaffleOrange,
              opacity: state.updating ? 0.5 : 1.0,
            },
            style,
          ]}>
          <Horizontal>
            <VerticalAround>
              <AnimatedText
                style={[
                  { fontSize: 48, textAlign: 'center', color: White },
                  animatedTextStyle,
                ]}>
                {prefixText}${priceFmt(policy?.price)}
                {''}
                <AnimatedText style={[{ fontSize: 24 }, animatedTextStyle]}>
                  {PolicyShortFrequencyLabel(policy)}
                </AnimatedText>
              </AnimatedText>
            </VerticalAround>
            {(pressed || state.updating) && (
              <PlainView style={{ position: 'absolute' }}>
                <SpinnerBlack size={'small'} />
              </PlainView>
            )}
          </Horizontal>
        </Animated.View>
      </TouchableOpacity>
    </Animated.View>
  );
};

export default PriceButton;
